<template>
  <div
    class="campaign d-flex flex-column"
  >
    <v-container
      fluid
      ref="header"
      class="campaign-card-header flex-shrink-0 d-flex align-center pr-6"
    >
      <v-card-title
        v-if="!editing"
        class="title text-truncate d-inline-block py-2"
      >
        {{ campaign.title }}
      </v-card-title>
      <v-hover
        v-slot="{ hover }"
      >
        <v-text-field 
          v-if="editable"
          v-model="configs.title.data"
          outlined
          dense
          hide-details
          @blur="configs.title.focus=false"
          @focus="configs.title.focus=true"
          :class="{ 'active': hover || configs.title.focus }"
          class="title-input elevation-0 flex-grow-0"
        />
      </v-hover>

      <!-- <v-spacer /> -->

      <div class="brief d-flex align-center text-body-2">
        <v-tooltip 
          top
          open-delay="250"
          transition="fade-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              v-show="!editing"
              text
              large
              color="grey darken-1"
              class="item mx-1"
              :class="{ 'enabled': editing }"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left>
                {{ icons.impressions }}
              </v-icon>
              <span class="text">{{ campaign.impressions | numeral('0,0') }}</span>
            </v-btn>
          </template>
          <span class="hint">Impressões Contratadas</span>
        </v-tooltip>
        <v-tooltip 
          top
          open-delay="250"
          transition="fade-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              text
              large
              :color="editing&&!configs.budget.valid ? 'error' : editing&&config=='budget' ? 'primary' : 'grey darken-1'"
              class="item mx-1"
              :class="{ 'active': editing&&config=='budget', 'editable': admin, 'enabled': editing }"
              v-bind="attrs"
              v-on="on"
              @click="toggleConfig('budget')"
            >
              <v-icon left>
                {{ icons.budget }}
              </v-icon>
              <span class="text">{{ configs.budget.data | numeral('$ 0,0') }}</span>
            </v-btn>
          </template>
          <span class="hint">Orçamento Contratado</span>
        </v-tooltip>
        <v-tooltip 
          top
          open-delay="250"
          transition="fade-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              v-show="!editing||!hasChildren"
              text
              large
              :color="editing&&config=='period' ? 'primary' : 'grey darken-1'"
              class="item text-capitalize mx-1"
              :class="{ 'active': editing&&config=='period', 'editable': admin, 'enabled': editing }"
              v-bind="attrs"
              v-on="on"
              @click="toggleConfig('period')"
            >
              <v-icon left>
                {{ icons.calendar }}
              </v-icon>
              <span class="text">{{ configs.period.data | formatPeriod }}</span>
            </v-btn>
          </template>
          <span class="hint">Período de Duração</span>
        </v-tooltip>

        <v-tooltip 
          top
          open-delay="250"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-expand-x-transition>
              <v-btn 
                v-show="admin&&!hasChildren&&editing"
                text
                large
                :color="editing&&config=='cities' ? 'primary' : 'grey darken-1'"
                class="item mx-1"
                :class="{ 'active': editing&&config=='cities', 'editable': admin, 'enabled': editing }"
                v-bind="attrs"
                v-on="on"
                @click="toggleConfig('cities')"
              >
                <v-icon left>
                  {{ icons.cities }}
                </v-icon>
                <span class="text text-capitalize">
                  {{ configs.cities.title }}
                </span>
              </v-btn>
            </v-expand-x-transition>
          </template>
          Cidades
        </v-tooltip>

        <v-tooltip 
          top
          open-delay="250"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-expand-x-transition>
              <v-btn 
                v-show="admin&&!hasChildren&&editing"
                text
                large
                :color="editing&&config=='visible' ? 'primary' : 'grey darken-1'"
                class="item mx-1"
                :class="{ 'active': editing&&config=='visible', 'editable': admin, 'enabled': editing }"
                v-bind="attrs"
                v-on="on"
                @click="toggleConfig('visible')"
              >
                <v-icon left>
                  {{ configs.visible.options[configs.visible.data].icon }}
                </v-icon>
                <span class="text text-capitalize">
                  {{ configs.visible.options[configs.visible.data].text }}
                </span>
              </v-btn>
            </v-expand-x-transition>
          </template>
          Visibilidade
        </v-tooltip>

        <v-tooltip 
          top
          open-delay="250"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-expand-x-transition>
              <v-btn 
                v-show="admin&&!hasChildren&&editing"
                text
                large
                :color="editing&&config=='paid' ? 'primary' : 'grey darken-1'"
                class="item mx-1"
                :class="{ 'active': editing&&config=='paid', 'editable': admin, 'enabled': editing }"
                v-bind="attrs"
                v-on="on"
                @click="toggleConfig('paid')"
              >
                <v-icon left>
                  {{ configs.paid.options[configs.paid.data].icon }}
                </v-icon>
                <span class="text text-capitalize">
                  {{ configs.paid.options[configs.paid.data].text }}
                </span>
              </v-btn>
            </v-expand-x-transition>
          </template>
          Modo
        </v-tooltip>

        <v-tooltip 
          top
          open-delay="250"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-expand-x-transition>
              <v-btn 
                v-show="groupedIn==null&&admin&&editing&&enableGroups"
                text
                large
                :color="editing&&config=='children' ? 'primary' : 'grey darken-1'"
                class="item mx-1"
                :class="{ 'active': editing&&config=='children', 'editable': admin, 'enabled': editing }"
                v-bind="attrs"
                v-on="on"
                @click="toggleConfig('children')"
              >
                <v-icon left>
                  {{ icons.children }}
                </v-icon>
                <span class="text text-capitalize">
                  {{ configs.children.title }}
                </span>
              </v-btn>
            </v-expand-x-transition>
          </template>
          Grupos de {{ getDictionary('ads') }}
        </v-tooltip>
      </div>

      <v-spacer />

      <div 
        class="version-selection"
        v-if="!hasChildren&&campaign.active!=2&&campaign.approval!=1"
      >
        <v-tooltip 
          top
          open-delay="250"
          transition="fade-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip 
              label
              small
              :disabled="editing"
              :outlined="version==0"
              color="success"
              class="version-label px-2 rounded-r-0"
              v-bind="attrs"
              v-on="on"
              @click="switchVersion(1)"
            >
              <!-- <v-icon left></v-icon> -->
              {{ statusLabel }}
            </v-chip>
          </template>
          {{ version==0 ? 'Clique para ver a' : 'Vendo' }} versão publicada
        </v-tooltip>
        <v-tooltip 
          top
          open-delay="250"
          transition="fade-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip 
              v-if="campaign.approval!=1"
              label
              small
              :outlined="version==1"
              :color="campaign.approval==2 ? 'error' : campaign.approval==null ? 'primary' : 'grey'"
              class="version-label px-2 rounded-l-0"
              v-bind="attrs"
              v-on="on"
              @click="switchVersion(0)"
            >
              {{ approvalLabel }}
            </v-chip>
          </template>
          {{ version==1 ? 'Clique para ver a' : 'Vendo' }} versão em edição
        </v-tooltip>
      </div>

      <v-tooltip 
        top
        open-delay="250"
        transition="fade-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-expand-x-transition>
            <v-btn 
              v-show="!editing"
              icon
              color="grey lighten-1"
              class="ml-2"
              size="40"
              v-bind="attrs"
              v-on="on"
              @click="edit"
            >
              <v-icon>
                {{ icons.config }}
              </v-icon>
            </v-btn>
          </v-expand-x-transition>
        </template>
        Alterar configurações da {{ getDictionary('campaign') }}
      </v-tooltip>

      <v-tooltip 
        top
        open-delay="250"
        transition="fade-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            v-show="(campaign.airing||afterEnd)&&!editing"
            icon
            color="primary"
            class="ml-2"
            v-bind="attrs"
            v-on="on"
            @click="dashboard"
          >
            <v-icon>
              {{ icons.dashboard }}
            </v-icon>
          </v-btn>
        </template>
        Dashboard
      </v-tooltip>

      <div 
        v-if="!editing&&campaign.active!=2"
        class="completion flex-shrink-0 ml-4"
      >
        <v-progress-circular
          :value="completion"
          :indeterminate="analytics.impressions==null"
          :rotate="-90"
          size="36"
          :color="campaign.active&&analytics.impressions!=null ? 'success' : 'grey lighten-3'"
          class="completion-progress"
        />
        <m-btn-long
          :icon="campaign.active&&!afterEnd ? beforeStart&&!on ? icons.clock : (view.activate.hover ? icons.pause : icons.play) : afterEnd ? icons.check : (view.activate.hover ? icons.play : icons.pause)"
          small
          :color="afterEnd || (view.activate.hover&&campaign.active||!view.activate.hover&&!campaign.active) ? 'grey darken-2' : 'success'"
          :press-color="campaign.active ? 'grey' : 'success'"
          :disabled="loading||afterEnd"
          :tooltip="activateConfig.tooltip"
          :duration="activateConfig.duration"
          @hover="activateHover"
          @cancel="activate(false)"
          @press="activate(true)"
        />
      </div>

      <v-tooltip 
        top
        open-delay="250"
        transition="fade-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-expand-x-transition>
            <v-btn 
              v-show="hasAds&&updated&&(!hasChanged||!editing)&&campaign.approval==null"
              :loading="loading"
              depressed
              color="success"
              class="ml-4"
              v-bind="attrs"
              v-on="on"
              @click="toggleDialog(true, 'publish')"
            >
              Publicar
            </v-btn>
          </v-expand-x-transition>
        </template>
        Publicar {{ this.campaign.active==2 ? 'Nova '+this.getDictionary('campaign') : 'Alterações' }}
      </v-tooltip>

      <v-menu
        bottom
        right
        offset-y
        open-on-hover
        :close-on-click="false"
        :close-on-content-click="false"
        close-delay="500"
        transition="fade-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-expand-x-transition>
            <v-btn 
              v-show="updated&&campaign!=null&&campaign.approval==2"
              :loading="loading"
              depressed
              color="error"
              outlined
              class="ml-4"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left>
                {{ icons.info }}
              </v-icon>
              Reprovada
            </v-btn>
          </v-expand-x-transition>
        </template>
        <v-card 
          v-show="campaign.message!=null"
          max-width="320"
        >
          <v-card-title class="text-overline py-2">
            Feedback
          </v-card-title>
          <v-divider />
          <v-card-text class="text-body-1 font-weight-medium">
            {{ campaign.message }}
          </v-card-text>
        </v-card>
      </v-menu>

      <v-tooltip 
        top
        open-delay="250"
        transition="fade-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-expand-x-transition>
            <v-card
              v-show="admin&&updated&&!editing&&campaign.approval==0"
              outlined
              v-bind="attrs"
              v-on="on"
              class="ml-4 pl-4 text-button grey--text text--darken-2"
            >
              Aprovar?
              <v-divider vertical />
              <v-btn 
                :disabled="loading"
                icon
                tile
                color="success"
                @click="toggleDialog(true, 'approve')"
              >
                <v-icon>{{ icons.check }}</v-icon>
              </v-btn>
              <v-btn 
                :disabled="loading"
                icon
                tile
                color="error"
                @click="toggleDialog(true, 'reject')"
              >
                <v-icon>{{ icons.close }}</v-icon>
              </v-btn>
            </v-card>
          </v-expand-x-transition>
        </template>
        Aprovar/Reprovar {{ getDictionary('campaign') }}
      </v-tooltip>

      <v-tooltip 
        top
        open-delay="250"
        transition="fade-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-expand-x-transition>
            <v-btn 
              v-show="admin&&!hasChildren&&!hasChanged&&editing&&!campaign.airing"
              icon
              :disabled="loading"
              color="error lighten-1"
              class="ml-4"
              size="40"
              v-bind="attrs"
              v-on="on"
              @click="remove"
            >
              <v-icon>
                {{ icons.remove }}
              </v-icon>
            </v-btn>
          </v-expand-x-transition>
        </template>
        Excluir {{ getDictionary('campaign') }}
      </v-tooltip>

      <v-tooltip 
        top
        open-delay="250"
        transition="fade-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-expand-x-transition>
            <v-btn 
              v-show="updated&&editing&&hasChanged&&validated"
              :loading="loading"
              color="primary"
              class="ml-4"
              v-bind="attrs"
              v-on="on"
              @click="save"
            >
              {{ isNew ? 'Criar' : 'Salvar' }}
            </v-btn>
          </v-expand-x-transition>
        </template>
        {{ isNew ? 'Criar '+getDictionary('campaign') : 'Salvar Alterações' }}
      </v-tooltip>

      <v-btn 
        v-if="closeable"
        icon
        color="grey"
        class="ml-4"
        size="40"
        @click="editing&&hasChanged ? toggleDialog(true, 'close') : close()"
      >
        <v-icon>
          {{ icons.close }}
        </v-icon>
      </v-btn>
    </v-container>

    <v-divider v-show="editing" />

    <v-container
      v-show="editing"
      fluid
      v-resize="setContentHeight"
      ref="config-content"
      class="config-content fill-height flex-grow align-center justify-center pa-0"
      :style="{ 'min-height': contentHeight+'px' }"
    >
      <v-fade-transition>
        <div 
          v-if="config=='budget'"
          class="config-controls budget-controls pt-6 d-flex flex-column align-center justify-center"
        >
          <v-select
            v-show="!hasChildren"
            v-model="configs.contract.data"
            :label="configs.contract.title"
            :items="configs.contract.options"
            item-value="id"
            item-text="advertiser"
            hide-details
            outlined
            :disabled="configs.contract.disabled||!editable"
            ref="contract"
            class="config-control contract-control"
            style="width: 320px"
          >
            <template v-slot:selection="data">
              <div class="contract-control-item">
                <p 
                  class="title text-overline"
                >
                  {{ data.item | formatContract }}
                </p>
                <span 
                  class="subtitle text-caption"
                >
                  {{ data.item.advertiser }}
                </span>
              </div>
            </template>
            <template v-slot:item="data">
              <div class="contract-control-item">
                <p 
                  class="title text-overline"
                >
                  {{ data.item | formatContract }}
                </p>
                <span 
                  class="subtitle text-caption"
                >
                  {{ data.item.advertiser }}
                </span>
              </div>
            </template>
          </v-select>
          <budget-picker 
            :budget="configs.budget.data"
            :max="maxBudget"
            :min="minBudget"
            :price="price"
            :disabled="configs.budget.disabled||hasChildren||!editable"
            :admin="admin"
            :height="contentHeight"
            class="config-control budget-control flex-shrink-0 mt-4"
            @validate="(value) => validateConfig('budget', value)"
            @update="(value) => updateConfig('budget', value)"
          />
        </div>
        <div 
          v-else-if="config=='period'"
          class="config-controls period-controls fill-height d-flex align-center justify-center"
        >
          <period-date
            :selected="configs.period.data"
            :started="campaign.airing==1"
            :min="configs.period.min"
            :max="configs.period.max"
            :disabled="hasChildren||!editable"
            :height="contentHeight"
            header
            width="320"
            class="config-control period-control flex-shrink-0"
            @update="(value) => updateConfig('period', value)"
          />
        </div>
        <div 
          v-else-if="config=='cities'"
          class="config-controls cities-controls d-flex align-center justify-center"
        >
          <m-city-select
            :cities="allowedCities"
            :selected="configs.cities.data"
            :disabled="configs.cities.disabled||!editable"
            selectable
            multiple
            searchable
            :weightable="weightableCities"
            outlined
            max-width="75vw"
            min-width="400"
            :max-height="contentHeight-96"
            @message="toggleMessage"
            @update="(value) => updateConfig('cities', value)"
          />
        </div>
        <div 
          v-else-if="config=='paid'"
          class="config-controls paid-controls d-flex align-center justify-center"
        >
          <m-select
            :options="configs.paid.options"
            :selected="configs.paid.data"
            :disabled="configs.paid.disabled||!editable"
            @change="(value) => updateConfig('paid', value)"
          />
        </div>
        <div 
          v-else-if="config=='visible'"
          class="config-controls visible-controls d-flex align-center justify-center"
        >
          <m-select
            :options="configs.visible.options"
            :selected="configs.visible.data"
            :disabled="configs.visible.disabled||!editable"
            @change="(value) => updateConfig('visible', value)"
          />
        </div>
        <div 
          v-else-if="config=='children'"
          class="config-controls children-controls d-flex align-center justify-center"
        >
          <v-card
            outlined
            max-width="360"
            min-width="280"
            :max-height="contentHeight-96"
          >
            <v-text-field
              v-model="configs.children.search"
              placeholder="Buscar..."
              solo
              flat
              hide-details
              class="group-search pl-2 py-1"
            />
            <v-divider />
            <v-list
              max-height="320"
              class="scrollable"
            >
              <v-list-item-group
                v-model="configs.children.data"
                multiple
                :disabled="configs.children.disabled||!editable"
                :max-height="`calc(100% - 56px)`"
                class="overflow-y-auto"
              >
                <v-list-item
                  v-for="(item, i) in groupOptions"
                  :key="`item-${i}`"
                  :value="item.id"
                  active-class="primary--text"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action class="mr-4">
                      <v-checkbox
                        :input-value="active"
                        :color="active ? 'primary' : 'grey'"
                      />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title 
                        v-text="item.title" 
                        :class="[active ? 'primary--text' : 'grey--text text--darken-1']"
                      />
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </div>
        <!-- <div 
          v-else-if="config=='pois'"
          class="config-controls location-controls fill-height d-flex justify-center"
        >
          <location-list
            :zones="zones"
            :selected="zonedGeofences"
            :height="contentHeight"
            width="320"
            class="config-control location-control-list flex-shrink-0"
            @update="updateLocation"
          >
            <template v-slot:city>
              <v-btn
                text
                block
                disabled
                large
                height="52"
                class="text-overline px-3"
              >
                Rio de Janeiro
                <v-spacer />
                <v-icon>
                  {{ icons.menu }}
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:layers>
              <map-layers
                :selected="configs.pois.layer"
                minimal
                class="py-2"
                @update="onLayerChange"
              />
            </template>
          </location-list>
          <v-divider class="divider" vertical />
          <location-map 
            :outline="mapData"
            :layers="['outline']"
            :layer="configs.pois.layer"
            :options="map.options"
            ref="map"
            class="config-control location-control-map flex-grow-1"
            @layer-change="onLayerChange"
          />
        </div> -->
      </v-fade-transition>
    </v-container>

    <v-overlay 
      :value="editing&&!updated"
      color="white"
      absolute
      opacity=".9"
      class="loading-overlay text-center"
    >
      <loading class="loading mb-4" />
      <span class="d-block text-overline grey--text text--lighten-1">
        Carregando
      </span>
    </v-overlay>

    <v-dialog
      v-model="dialog.toggle"
      content-class="dialog-confirm"
      max-width="360"
      :persistent="dialog.toggle&&loading"
    >
      <v-card>
        <v-card-title>
          {{ dialog.title }}
        </v-card-title>

        <v-card-text 
          class="text-body-1 py-4"
          :inner-html.prop="dialog.text | parseText"
        />

        <v-textarea
          v-if="dialog.context=='reject'"
          v-model="dialog.message.value"
          :disabled="dialog.loading"
          label="Feedback"
          outlined
          hide-details
          class="pa-6 pt-0"
        >
          <!--  -->
        </v-textarea>

        <v-card-actions class="py-4">
          <v-btn
            text
            :disabled="dialog.loading"
            color="grey"
            class="px-4"
            @click="dialogAction(dialog.actions.cancel.action, dialog.actions.cancel.args)"
          >
            {{ dialog.actions.cancel.text }}
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="dialog.context=='reject'||dialog.context=='remove'"
            :loading="dialog.loading"
            text
            color="error"
            class="px-4"
            @click="dialogAction(dialog.actions.deny.action, dialog.actions.deny.args)"
          >
            {{ dialog.actions.deny.text }}
          </v-btn>
          <v-btn
            v-else
            :loading="dialog.loading"
            :color="dialog.context=='approve'||dialog.context=='publish' ? 'success' : 'primary'"
            depressed
            class="px-4"
            @click="dialogAction(dialog.actions.confirm.action, dialog.actions.confirm.args)"
          >
            {{ dialog.actions.confirm.text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>

.campaign {
  min-height: 72px;
}
.campaign-card-header {
  min-height: 72px;
}
.campaign-card-header .version-selection .version-label {
  font-size: 10px !important;
  font-weight: 500 !important;
  letter-spacing: 0.125em;
  border-color: var(--light-border) !important;
}

.campaign-card-header .brief .item {
  border-bottom: 2px solid transparent !important;
  cursor: default;
}
.campaign-card-header .brief .item.active {
  border-color: inherit !important;
}
.campaign-card-header .brief .item.enabled {
  cursor: pointer;
}

.campaign-card-header .completion {
  position: relative;
  width: 36px;
  height: 36px;
}
.campaign-card-header .completion-progress {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.campaign-card-header .hint {
  line-height: 1rem !important;
  font-size: .725rem !important;
}

.campaign .config-content {
  width: 100%;
  min-height: 100%;
}
.campaign .config-controls {
  width: 100%;
  min-height: 0;
}


.campaign .title-input {
  width: 360px;
}
.campaign .title-input .v-input__slot {
  box-shadow: none !important;
}
.campaign .title-input.v-input--is-focused input {
  color: inherit;
}
.campaign .title-input fieldset {
  border: 1px solid transparent;
}
.campaign .title-input:hover fieldset {
  border-color: rgba(0, 0, 0, 0.12) !important;
}
.campaign .title-input.v-input--is-focused fieldset {
  border-color: var(--primary) !important;
}
.campaign .title-input input {
  font-size: 1.125rem !important;
  font-weight: 500;
  min-height: 48px;
  letter-spacing: 0.0125em !important;
  color: var(--mobees-black);
}

.campaign .contract-control, .campaign .budget-control .budget-field {
  width: 320px;
}

.contract-control-item {
  padding-top: 4px;
  padding-bottom: 4px;
}
.contract-control-item .title {
  margin-bottom: 0;
  padding-top: 4px;
  line-height: 1.25rem;
}

.campaign .loading-overlay .loading {
  width: 48px;
  height: 48px;
  opacity: .8;
}

.campaign .config-content .divider {
  margin: 0 !important;
}

</style>

<script>
  import { mdiCheck, mdiPlay, mdiPause, mdiDotsVertical, mdiImage, mdiCurrencyUsd, mdiCalendarRange, mdiPoll, mdiClose, mdiClock, mdiPencil, mdiMapMarker, mdiCog, mdiTrashCan, mdiEye, mdiEyeOff, mdiCurrencyUsdOff , mdiSleep, mdiCircleOffOutline, mdiImageMultiple, mdiInformation, mdiGiftOutline, mdiCityVariantOutline } from '@mdi/js';
  import services from '@/services'
  import { sync } from 'vuex-pathify'
  const moment = require('moment');
  import numeral from 'numeral'

  export default {
    props: {
      campaign: {
        type: Object,
        default: () => {}
      },
      analytics: {
        type: Object,
        default: () => {
          return { 
            impressions: null
          }
        }
      },
      active: {
        type: Number,
        default: 0
      },
      campaigns: {
        type: Array,
        default: () => []
      },
      brand: {
        type: Object,
        default: () => {}
      },
      cities: {
        type: Object,
        default: () => {}
      },
      loading: {
        type: Boolean,
        default: false
      },
      updated: {
        type: Boolean,
        default: false
      },
      contracts: {
        type: Object,
        default: () => {}
      },
      groupedIn: {
        type: Number,
        default: null
      },
      user: {
        type: Object,
        default: () => {}
      },
      admin: {
        type: Boolean,
        default: false
      },
      version: {
        type: Number,
        default: 0
      },
      editing: {
        type: Boolean,
        default: false
      },
      closeable: {
        type: Boolean,
        default: false
      },
    },
    
    data: () => ({
      icons: {
        check: mdiCheck, 
        play: mdiPlay, 
        pause: mdiPause, 
        dots: mdiDotsVertical, 
        impressions: mdiImage, 
        budget: mdiCurrencyUsd, 
        calendar: mdiCalendarRange,
        cities: mdiCityVariantOutline,
        dashboard: mdiPoll,
        close: mdiClose,
        clock: mdiClock,
        edit: mdiPencil,
        pois: mdiMapMarker,
        config: mdiCog,
        remove: mdiTrashCan,
        visible: mdiEye,
        invisible: mdiEyeOff,
        paid: mdiCurrencyUsd,
        probono: mdiCurrencyUsdOff,
        bonus: mdiGiftOutline,
        children: mdiImageMultiple,
        info: mdiInformation
      },
      configs: {
        title: {
          title: 'Título',
          data: '',
          focus: false,
          changed: false,
          disabled: false,
        },
        period: {
          title: 'Período',
          data: {
            start: '',
            end: '',
          },
          min: null,
          max: null,
          changed: false,
          disabled: false,
        },
        contract: {
          title: 'Contrato',
          data: null,
          options: [],
          changed: false,
          disabled: false,
        },
        budget: {
          title: 'Orçamento',
          data: 0,
          valid: false,
          changed: false,
          disabled: false,
        },
        cities: {
          title: 'Cidades',
          data: null,
          options: {},
          changed: false,
          disabled: false,
        },
        paid: {
          data: 2,
          changed: false,
          disabled: false,
          title: 'Tipo',
          options: {
            2: {
              value: 2,
              text: 'Paga',
              icon: mdiCurrencyUsd,
              weightable: true,
              disabled: false
            },
            1: {
              value: 1,
              text: 'Pro bono',
              icon: mdiCurrencyUsdOff,
              weightable: true,
              disabled: false
            },
            3: {
              value: 3,
              text: 'Bônus',
              icon: mdiGiftOutline,
              weightable: true,
              disabled: false
            },
            0: {
              value: 0,
              text: 'Calhau',
              icon: mdiSleep,
              weightable: false,
              disabled: false
            },
          },
        },
        visible: {
          title: 'Visível',
          data: 1,
          options: {
            1: {
              text: 'Visível',
              value: 1,
              icon: mdiEye
            },
            0: {
              text: 'Invisível',
              value: 0,
              icon: mdiEyeOff
            },
          },
          changed: false,
          disabled: false,
        },
        children: {
          title: 'Grupos',
          search: '',
          data: [],
          changed: false,
          disabled: false,
        },
        pois: {
          title: 'Locais',
          layer: 'geofences',
          data: [],
          changed: false,
          disabled: false,
        },
      },
      config: 'budget',
      dialog: {
        toggle: false,
        loading: false,
        title: 'Confirmar',
        text: '',
        context: null,
        message: {
          value: '',
          disabled: false,
        },
        actions: {
          confirm: {
            text: 'Confirmar',
            action: 'toggleDialog',
            args: [false]
          },
          deny: {
            text: 'Reprovar',
            action: 'toggleDialog',
            args: [false]
          },
          cancel: {
            text: 'Cancelar',
            action: 'toggleDialog',
            args: [false]
          },
        }
      },
      map: {
        geofences: {
          toggle: true,
          data: [],
        },
        options: {
          disableDefaultUI: true,
          // zoomControl: false,
          // gestureHandling: "none",
        }
      },
      view: {
        activate: {
          hover: false,
          duration: 2000,
          tooltip: {
            top: true,
            disabled: false,
            text: ''
          }
        }
      },
      contentHeight: '100%'
    }),

    computed: {
      toast: sync('app/toast'),
      
      activateConfig () {
        const config = this.view.activate;
        const active = this.campaign.active;
        const beforeStart = this.beforeStart;
        const afterEnd = this.afterEnd;
        const completion = this.completion;
        const dict = this.getDictionary('campaign');
        return _.merge(config, { 
          tooltip: {
            text: afterEnd ? dict+' Finalizada' : (active ? beforeStart ? dict+' Agendada' : 'Pausar' : 'Ativar') + ' (' + completion + '%)'
          }
        })
      },

      editable () {
        return this.editing && this.campaign.approval!=0;
      },

      completion () {
        const total = this.campaign.impressions;
        const impressions = this.analytics.impressions;
        const completion = parseInt((impressions/total) * 100);
        return impressions==null||impressions==0||total==0 ? 100 : completion;
      },

      impressions () {
        return _.ceil(this.configs.budget.data / this.price);
      },

      price () {
        let price = _.isNil(this.configs.contract.data)||!_.has(this.contracts, this.configs.contract.data) ? .15 : this.contracts[this.configs.contract.data].price;
        if (this.hasChildren&&!_.isEmpty(this.campaigns)) {
          const campaigns = _.keyBy(_.clone(this.campaigns), 'id');
          const contracts = this.contracts;
          const consolidated = _.reduce(this.campaign.children, (a, child) => {
            const campaign = campaigns[child];
            const contract = contracts[campaign.contract];
            a.budget += campaign.budget;
            a.impressions += (contract.impressions * campaign.budget) / contract.budget;
            return a;
          }, { budget: 0, impressions: 0 });
          price = consolidated.budget / consolidated.impressions;
        }
          
        return price;
      },

      maxBudget () {
        const parent = _.isNil(this.groupedIn) ? null : this.campaigns[this.groupedIn];
        const contract = _.isNil(this.configs.contract.data)||!_.has(this.contracts, this.configs.contract.data) ? null : this.contracts[this.configs.contract.data];
        const total = _.isNil(parent) ? _.isNil(contract) ? 0 : contract.budget : parent.budget;
        const campaigns = _.keyBy(_.clone(this.campaigns), 'id');
        const provisioned = _.isNil(parent) ? _.isNil(contract) ? 0 : contract.provisioned : _.sumBy(parent.children, c => campaigns[c].budget);
        const max = _.isNil(contract) ? 0 : total - provisioned + this.campaign.budget;
        return max < 0 ? 0 : max;
      },
      minBudget () {
        const children = !this.hasChildren ? null : this.configs.children.data;
        const campaigns = _.keyBy(_.clone(this.campaigns), 'id');
        const min = _.isNil(children)||_.some(children, c => !_.has(campaigns, c)) ? 0 : _.sumBy(children, c => {
          return campaigns[c].budget;
        });
        return min < 0 ? 0 : min;
      },

      statusLabel () {
        return this.campaign.airing ? 'NO AR' : this.campaign.active==0 && moment().isSameOrBefore(this.campaign.period.end) ? 'PAUSADA' : this.campaign.active==1&&moment().isBefore(this.campaign.period.start) ? 'AGENDADA' : 'FINALIZADA';
      },
      approvalLabel () {
        return this.campaign.approval==2 ? 'NÃO APROVADA' : this.campaign.approval==0 ? 'EM APROVAÇÃO' : 'EM EDIÇÃO';
      },

      isNew () {
        return !_.isNil(this.campaign) && this.campaign.id=='new';
      },

      hasAds () {
        const count = !_.isNil(this.campaign) ? _.size(this.campaign.ads) : 0;
        return !count==0 && !(count==1&&_.has(this.campaign.ads, 'new'));
      },

      hasChildren () {
        return !_.isNil(this.campaign) ? _.size(this.campaign.children)>0 : 0;
      },
      
      hasChanged () {
        return _.some(this.configs, ['changed', true]);
      },
      beforeStart () {
        return moment().isBefore(this.campaign.period.start);
      },
      afterEnd () {
        return moment().isAfter(this.campaign.period.end);
      },
      enableGroups () {
        return _.size(this.campaigns)>1;
      },
      groupOptions () {
        const query = this.configs.children.search;
        const key = _.isNil(query) || query=='' ? null : new RegExp(query.normalize('NFD').replace(/\p{Diacritic}/gu, ''), 'i');
        return _.orderBy(_.filter(this.campaigns, campaign => {
          return campaign.id!=this.campaign.id && (_.isNil(key) || key.test(campaign.title.normalize('NFD').replace(/\p{Diacritic}/gu, '')))
        }), [c => _.indexOf(this.campaign.children, c.id)>=0 ? 1 : 0], ['desc'])
      },
      allowedCities () {
        let allowed = _.mapValues(_.clone(this.cities), city => {
          return {
            title: city.title,
            id: city.id,
            weight: null,
            locked: false
          }
        })
        const internal = this.configs.paid.data==0;
        if (this.admin&&internal) allowed[0] = { 
          id: 0, 
          title: 'Calhau', 
          weight: null 
        }
        return allowed;
      },
      weightableCities () {
        const paid = this.configs.paid.data;
        const weightable = _.isNil(paid) ? false : this.configs.paid.options[paid].weightable;
        return weightable;
      },
      mapData () {
        return {
          toggle: true,
          data: this.zonedGeofences,
        }
      },
      geofences () {
        const cities = this.cities;
        return _.reduce(cities, (geofences, city) => {
          _.each(city.zones, zone => {
            _.each(zone.geofences, g => {
              geofences[g.id] = {
                id: g.id,
                title: g.title,
                url: g.url,
                zone: { id: zone.id, title: zone.title, url: zone.url },
                city: { id: city.id, title: city.title, url: city.url },
                state: _.clone(city.state),
                country: _.clone(city.country),
              }
            });
          })
          return geofences;
        }, {});
      },
      zones () {
        return _.mapValues(_.groupBy(this.geofences, 'zone.id'), (zone, z) => {
          const g = _.find(zone, ['zone.id', parseInt(z)]);
          return {
            id: g.zone.id,
            title: g.zone.title,
            geofences: zone
          }
        });
      },
      zonedGeofences () {
        return _.reject(_.map(_.size(this.configs.pois.data) == 0 ? _.values(this.geofences) : this.configs.pois.data, id => {
            return _.find(this.geofences, ['id', id])
          }), g => _.isNil(g));
      },

      validated () {
        return _.every(_.filter(this.configs, config => _.has(config, 'valid')), ['valid', true]);
      }
    },

    watch: {
      campaign: {
        immediate: true,
        deep: true,
        handler (campaign) {
          console.log('campaign', campaign);

          this.configs.title.data = campaign.title;
          this.configs.budget.data = campaign.budget;
          this.configs.contract.data = campaign.contract;
          this.configs.contract.disabled = campaign.active!=2;
          this.configs.period.data = campaign.period;
          this.configs.paid.data = campaign.paid;
          this.configs.visible.data = campaign.visible;
          this.configs.children.data = campaign.children;
          if (campaign.cities!=null) this.configs.cities.data = _.mapValues(campaign.cities, city => {
            if (_.isNil(city.title)) city.title = this.cities[city.id].title;
            return city;
          });
          this.configs.pois.data = campaign.pois;

          if (campaign.id=='new') {
            if (_.isNil(this.configs.contract.data)) this.configs.contract.data = this.findContract();
            const contract = !_.has(this.contracts, this.configs.contract.data) ? null : this.contracts[this.configs.contract.data];
            this.configs.budget.data = this.maxBudget;
            this.configs.period.data = !_.isNil(contract) ? 
              _.mapValues(_.clone(contract.period), (p,k) => {
                return k=='start' && moment().isAfter(p, 'd') ? moment().format('YYYY-MM-DD') : p
              }) : campaign.period;

            _.each(this.configs, (config, c) => {
              config.changed = true;
            });
          }
        }
      },
      configs: {
        deep: true,
        handler (configs, before) {
          _.each(configs, (config, c) => {
            if (!_.isEqual(config.data, this.campaign[c])) console.log(c, 'changed', config.data);
            config.changed = !_.isEqual(config.data, this.campaign[c]);
          })
        }
      },
      'configs.contract.data': {
        handler (id, before) {
          const contract = _.isNil(id) ? null : this.contracts[id];
          if (id!=before) {
            if (this.campaign.active==2&&!_.isNil(contract)) {
              this.$nextTick(() => {
                this.configs.budget.data = this.maxBudget;
                this.configs.period.data = _.mapValues(_.clone(contract.period), (p,k) => {
                  return k=='start' && moment().isAfter(p, 'd') ? moment().format('YYYY-MM-DD') : p
                });
                this.configs.period.min = contract.period.start;
                this.configs.period.max = contract.period.end;
              })
            }
          }
        }
      },
      contracts: {
        immediate: true,
        deep: true,
        handler (contracts) {
          this.configs.contract.options = _.orderBy(_.map(contracts, (contract, c) => {
            return {
              disabled: moment().isAfter(contract.period.end),
              ...contract
            }
          }), [c => c.total-c.provisioned, 'period.end', 'period.start'], ['desc', 'desc', 'desc']);
          if (this.campaign.id=='new'&&_.isNil(this.configs.contract.data)) {
            this.configs.contract.data = this.findContract();
          }else{
            const contract = _.isNil(this.campaign.contract)||!_.has(this.contracts, this.configs.contract.data) ? null : _.clone(this.contracts[this.configs.contract.data]);
            this.configs.period.min = !_.isNil(contract) ? contract.period.start : null;
            this.configs.period.max = !_.isNil(contract) ? contract.period.end : null;
          }
        }
      },
      loading (b) {
        if (this.dialog.toggle) {
          this.dialog.loading = b;
          this.dialog.toggle = b;
        }
      },
      weightableCities (b) {
        let selected = this.configs.cities.data;
        const size = _.size(selected);
        const internal = this.configs.paid.data==0;
        if (!b&&size>0) {
          selected = _.mapValues(_.clone(selected), city => {
            return { ...city, weight: null, locked: false }
          });
        }else if (b&&!internal&&size>0&&_.some(selected, ['weight', null])) {
          const weight = _.round(100/size, 2);
          selected = _.mapValues(_.clone(selected), city => {
            return { ...city, weight }
          });
        }
      }
    },

    methods: {
      ...services,

      switchVersion (v) {
        this.$emit('switch-version', v);
      },

      toggleConfig (config) {
        this.config = config;
      },

      updateConfig (config, value) {
        // console.log('updateConfig', config, value);
        this.configs[config].data = value;
      },

      validateConfig (config, value) {
        this.configs[config].valid = value;
      },

      updateLocation (geofences) {
        this.configs.pois.data = geofences;
      },

      findContract () {
        return _.isNil(this.campaign.contract) && !_.isEmpty(this.configs.contract.options) ? 
          _.find(_.map(_.orderBy(this.configs.contract.options, ['period.end', 'period.start', 'provisioned'], ['desc', 'desc', 'desc']), 'id')) : 
          null;
      },

      onLayerChange (layer) {
        this.configs.pois.layer = layer;
      },

      processChanges () {
        return _.mapValues(_.pickBy(this.configs, config => {
          return this.campaign.id=='new' || config.changed;
        }), c => c.data);
      },
      edit () {
        setTimeout(() => this.$emit('edit'), 250);
      },
      save () {
        const data = _.assign(this.processChanges(), { id: this.campaign.id });
        this.$emit('save', data);
      },
      remove () {
        if (this.campaign.approval!=1&&!this.dialog.toggle) {
          this.toggleDialog(true, 'remove');
        }else{
          this.dialog.loading = true;
          this.$emit('remove');
        }
      },
      approve (approved) {
        const message = this.dialog.message.value;
        this.$emit('approve', { approved, message });
      },
      publish () {
        if (this.campaign.approval==0) return;
        this.$emit('publish');
      },
      activate (confirm) {
        console.log(confirm)
        if (confirm||this.dialog.toggle) {
          console.log("activate", this.campaign.id);
          if (this.campaign.active==1&&!this.dialog.toggle) {
            this.toggleDialog(true, 'deactivate');
          }else{
            this.dialog.loading = (this.dialog.toggle);
            this.$emit('activate');
          }
          this.toggleToast(
            false,
            '',
            0,
            false
          );
        }else{
          const msg = this.active ? 'pausar' : 'ativar'
          this.toggleToast(
            true,
            `Mantenha pressionado para ${msg}`,
            7000,
            false
          );
        }
      },
      dashboard () {
        this.$emit('dashboard');
      },
      close () {
        this.$emit('close');
      },

      setContentHeight () {
        this.$nextTick(() => {
          const available = window.innerHeight - 40 - (this.campaign.id=='new' ? 0 : 240);
          let header = _.has(this.$refs, 'header') ? this.$refs['header'] : null;
          if (!!header) {
            this.contentHeight = available - header.getBoundingClientRect().height;
          }else{
            setTimeout($=>{
              $.setContentHeight();
            }, 500, this);
          }
        })
      },

      activateHover (b) {
        this.view.activate.hover = b;
      },

      toggleDialog (b, context) {
        const dialog = this.dialog;
        dialog.context = context;
        const campaign_dict = this.getDictionary('campaign');
        switch (context) {
          case 'remove':
            dialog.title = `Apagar ${campaign_dict}`;
            dialog.text = `Tem certeza que deseja apagar a ${campaign_dict} ${this.configs.title.data}?`;
            dialog.actions.deny.text = 'Apagar';
            dialog.actions.deny.action = 'remove';
            dialog.actions.deny.args = [];

            break;
          case 'deactivate':
            dialog.title = `Pausar ${campaign_dict}`;
            dialog.text = `Dependendo do tempo que a ${campaign_dict} fique pausada, *não poderemos garantir a entrega do total de impressões contratadas*. Tem certeza que deseja pausar a ${campaign_dict}?`;
            dialog.actions.confirm.text = 'Pausar';
            dialog.actions.confirm.action = 'activate';
            dialog.actions.confirm.args = [];

            break;
          case 'publish':
            dialog.title = `Publicar ${campaign_dict}`;
            dialog.text = `Tem certeza que deseja publicar a ${campaign_dict} ${this.configs.title.data}?`;
            dialog.actions.confirm.text = 'Publicar';
            dialog.actions.confirm.action = 'publish';
            dialog.actions.confirm.args = [];

            break;
          case 'approve':
            dialog.title = `Aprovar ${campaign_dict}`;
            dialog.text = `Tem certeza que deseja aprovar a ${campaign_dict} ${this.configs.title.data}?`;
            dialog.actions.confirm.text = 'Aprovar';
            dialog.actions.confirm.action = 'approve';
            dialog.actions.confirm.args = [1];

            break;
          case 'reject':
            dialog.title = `Reprovar ${campaign_dict}`;
            dialog.text = `Tem certeza que deseja reprovar a ${campaign_dict} ${this.configs.title.data}?`;
            dialog.actions.deny.text = 'Reprovar';
            dialog.actions.deny.action = 'approve';
            dialog.actions.deny.args = [0];

            break;
          case 'close':
            dialog.title = 'Descartar Alterações';
            dialog.text = `Existem alterações que não foram salvas na ${campaign_dict} ${this.configs.title.data} Deseja descartá-las?`;
            dialog.actions.confirm.text = 'Descartar';
            dialog.actions.confirm.action = 'close';
            dialog.actions.confirm.args = [];

            break;
        }
        this.dialog.toggle = b;
      },
      dialogAction (fn, args) {
        this[fn](...args)
      },
      toggleMessage (message) {
        this.$emit("message", message);
      },
    },

    mounted () {
      if (this.editing) {
        const options = this.configs.paid.options;
        _.each(options, (option, i) => {
          option.disabled = i!='0' && this.brand.category=='CAL';
        })
        const selected = this.configs.paid.data;
        this.configs.paid.data = options[selected].disabled ? _.find(options, ['disabled', false]).value : selected;
      }
    },

    updated () {
      setTimeout($=>{
        $.setContentHeight();
      }, 500, this);
    },

    filters: {
      formatContract (contract) {
        const start = moment(contract.period.start);
        const end = moment(contract.period.end);
        const format = start.year()==end.year() ? 'MMM' : 'MMM/YY';
        return `${numeral(contract.budget).format('$ 0[,]0')} | ${start.format(format)}–${end.format('MMM/YY')}`
      },
      formatPeriod (period) {
        const p = period;
        const isSameYear = moment().year()==moment(p.start).year();
        const isSameMonth = moment(p.start).month() == moment(p.end).month();
        const format = {
          start: isSameYear ? 'DD/MMM' : 'MMM/YY',
        }
        format.end = isSameMonth ? isSameYear ? 'DD' : 'MMM' : format.start;

        const start = moment(p.start).format(format.start).toString();
        const end = moment(p.end).format(format.end).toString();
        return start + ' – ' + end;
      },
      parseText (text) {
        return text.replace(/\*([^\*]+)\*/gim, "<b>$1</b>")
      }
    },

    components: {
      // LocationList: () => import('@/components/campaigns/LocationList'),
      // LocationMap: () => import('@/components/mMap'),
      PeriodDate: () => import('@/components/campaigns/PeriodDate'),
      BudgetPicker: () => import('@/components/campaigns/BudgetControl'),
      Loading: () => import('@/components/IconLoading'),
      mSelect: () => import('@/components/mSelect'),
      MBtnLong: () => import('@/components/mBtnLong'),
      MCitySelect: () => import('@/components/mCitySelect')
    }


  }
</script>